<template>
  <div class="myCustomers">
    <div class="left">
<!--      <el-date-picker-->
<!--          class="timesp"-->
<!--          v-model="time"-->
<!--          type="daterange"-->
<!--          value-format="yyyy-MM-dd"-->
<!--          range-separator="至"-->
<!--          start-placeholder="开始日期"-->
<!--          end-placeholder="结束日期">-->
<!--      </el-date-picker>-->
      <div class="times">
        <el-date-picker
            class="time1"
            value-format="yyyy-MM-dd"
            v-model="time1"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
<!--        <p style="padding: 0 5px;">至</p>-->
<!--        <el-date-picker-->
<!--            class="time1"-->
<!--            v-model="time2"-->
<!--            value-format="yyyy-MM-dd"-->
<!--            type="date"-->
<!--            placeholder="选择日期">-->
<!--        </el-date-picker>-->
      </div>
      <div class="times">至</div>
      <div class="times">
<!--        <el-date-picker-->
<!--            class="time1"-->
<!--            value-format="yyyy-MM-dd"-->
<!--            v-model="time1"-->
<!--            type="date"-->
<!--            placeholder="选择日期">-->
<!--        </el-date-picker>-->
<!--        <p style="padding: 0 5px;">至</p>-->
        <el-date-picker
            class="time1"
            v-model="time2"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="searchbox">
        <el-input
            class="search"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="search">
        </el-input>
        <el-button @click="searchCs">搜索</el-button>
      </div>
      <div class="left-xzbox">
        <el-collapse v-model="activeNames" accordion @change="handleChange">
          <el-collapse-item class="back" title="本店当天顾客" name="1">
            <div class="nr" v-for="(item, idx) in dayCs" :class="daCsIdx==idx?'xz':''" @click="getxz(item, idx)">
              <img src="../assets/img/my.png" alt="">
              <p>{{item.CustomerName}}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item class="back" title="本店所有顾客" name="2" >
            <div class="nr" v-for="(item, idx) in allCS" :class="allCSIdx==idx?'xz':''" @click="getxz(item, idx, 1)">
              <img src="../assets/img/my.png" alt="">
              <p>{{item.CustomerName}}</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="right">
      <div class="titles padding-30">顾客信息</div>
      <ul class="title-ul">
        <li>姓名</li>
        <li>性别</li>
        <li>出生日期</li>
        <li>年龄</li>
        <li>家庭住址</li>
        <li>联系方式</li>
        <li></li>
      </ul>
      <ul class="content-ul">
        <li>{{itemData.CustomerName?itemData.CustomerName:'-'}}</li>
        <li>{{sex}}</li>
        <li>{{itemData.CustomerBirthday}}</li>
        <li>{{year}}</li>
        <li style="display: block;" :title="itemData.CustomerAddress">
          {{itemData.CustomerAddress}}
        </li>
        <li>{{itemData.CustomerTel?itemData.CustomerTel:'-'}}</li>
        <li @click="ling"><p>登录</p></li>
      </ul>

      <div class="titles padding-50">
        <div>
          <span :class="tebidx==1?'tebxz':''" @click="teb(1)">检查信息</span>
          <span v-if="itemData.CustomerName" :class="tebidx==2?'tebxz':''" @click="teb(2)" style="margin-left: 30px;">回访列表</span>
        </div>
        <el-button v-if="tebidx==2" type="primary" size="small" @click="addshow">添加回访</el-button>
      </div>

      <div v-if="tebidx == 1">
        <ul class="title-ul">
          <li>报告编号</li>
          <li>报告状态</li>
          <li>检查日期</li>
          <li>创建日期</li>
          <li>详细报告</li>
        </ul>
        <div class="right-box">
          <ul class="content-ul" v-for="(item, idx) in consultingArr" :key="idx">
            <li>{{item.bh}}</li>
            <li>{{item.zt}}</li>
            <li>{{item.testTime}}</li>
            <li>{{item.cjTime}}</li>
            <li @click="go(item.ChooseId)"><p>查看详情</p></li>
          </ul>
        </div>
      </div>

      <div v-if="tebidx == 2">
        <ul class="title-ul">
          <li>回访编号</li>
          <li>回访时间</li>
          <li>回访类型</li>
          <li>回访方式</li>
          <li>回访状态</li>
          <li></li>
        </ul>
        <div class="right-box">
          <ul class="content-ul" v-for="(item, idx) in hflist" :key="idx">
            <li>{{item.CRId.slice(0, 8).toUpperCase()}}</li>
            <li>{{item.CRTime}}</li>
            <li>{{item.CRType}}</li>
            <li>{{item.CRMethod}}</li>
            <li>{{item.CRState}}</li>
            <li @click="ckhf(idx)"><p>查看详情</p></li>
          </ul>
        </div>
      </div>

    </div>


    <el-dialog
        title="添加回访"
        :visible.sync="showadd"
        width="75%"
        >

      <div class="hfwt">
        <span>回访时间：</span>
        <el-date-picker
            v-model="adddata.CRTime"
            type="datetime"
            :editable="false"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期">
        </el-date-picker>

        <span style="padding-left: 30px;">回访类型：</span>
        <el-select v-model="adddata.CRType" placeholder="请选择">
          <el-option
              v-for="item in hflx"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>


      <div class="hfwt">
        <span>回访方式：</span>
        <el-select v-model="adddata.CRMethod" placeholder="请选择">
          <el-option
              v-for="item in hffs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <span style="padding-left: 30px;">回访状态：</span>
        <el-select v-model="adddata.CRState" placeholder="请选择">
          <el-option
              v-for="item in hfzt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>


      <div class="hfwt">
        <span>回访内容：</span>
        <el-input
            style="width: 60vw"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="adddata.CRContent">
        </el-input>
      </div>

      <div class="hfwt">
        <span>顾客问题：</span>
        <el-input
            style="width: 60vw"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="adddata.CRProblem">
        </el-input>
      </div>

      <el-button v-if="tebidx==1" style="margin-top: 30px;" type="primary" @click="savecsfh">提交</el-button>
      <el-button v-if="tebidx==2" style="margin-top: 30px;" type="primary" @click="savecsfh">更新</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {Loading} from "element-ui";
import pb from '@/publicFn/baseFn'

export default {
  name: "myCustomers",
  data() {
    return {
      time1: '',
      time2: '',
      search: '',
      activeNames: '1',
      datas: [1,2,3,4],
      dayCs: [],
      allCS: [],
      daCsIdx: -1,
      allCSIdx: -1,
      itemData: {},
      consultingArr: [],
      time: [],
      isSsCs: true,
      showadd: false,
      textarea: '',
      options: [],
      hflx: [
        {
          value: '普通',
          label: '普通'
        },
        {
          value: '诊前',
          label: '诊前'
        },
        {
          value: '诊后',
          label: '诊后'
        },
        {
          value: '诊后术前',
          label: '诊后术前'
        },
        {
          value: '术后',
          label: '术后'
        },
        {
          value: '产品售后',
          label: '产品售后'
        },
        {
          value: '视觉训练',
          label: '视觉训练'
        },
        {
          value: '角膜塑形镜',
          label: '角膜塑形镜'
        }
      ],
      hfzt: [
        {
          value: '完成',
          label: '完成'
        },
        {
          value: '未完成',
          label: '未完成'
        }
      ],
      hffs: [
        {
          value: '电话',
          label: '电话'
        },
        {
          value: '微信',
          label: '微信'
        },
        {
          value: '上门',
          label: '上门'
        },
        {
          value: '到店',
          label: '到店'
        },
        {
          value: '邮件',
          label: '邮件'
        }
      ],
      adddata: {},
      hflist: [],
      tebidx: 1,
    }
  },
  watch: {
    activeNames: function (n, o) {
      if (n == 2) {
        this.getAllCs()
      }else {
        this.getDay()
      }
    }
  },
  computed: {
    sex() {
      let str = '-'
      if (this.itemData.CustomerGender) {
        this.itemData.CustomerGender=='0'?str = '女': str = '男'
      }
      return str
    },
    year() {
      if (this.itemData.CustomerBirthday) {
        return  pb.getYear(this.itemData.CustomerBirthday)
      }else {
        return '-'
      }
    }
  },
  created() {
    this.time = [pb.getTime(0), pb.getTime(1)]
    this.$store.commit('isShow/upBottom', false)
    this.getDay()
  },
  methods: {
    handleChange(val) {

    },
    ling() {
      if (!this.itemData.CustomerName) return false
      this.$store.commit('users/upCsUserItem', '')
      this.$store.commit('users/UpCsUser', '')
      this.$store.commit('users/upCsAge', '')
      this.$store.commit('physicianVisits/upCancellation', true)

      this.$store.commit('users/upCsUserItem', {
        name: this.itemData.CustomerName,
        tel: this.itemData.CustomerTel
      })

      if (this.$store.state.Time.timeObj.customerLogin_normal) {
        this._api.publicApi.addTime('customerLogin_normal', ((new Date()).valueOf() - this.$store.state.Time.timeObj.customerLogin_normal.ENTER)/1000)
      }
      this.$store.commit('Time/initTimeObj', {
        keys: "customerLogin_normal",
        val: {
          ENTER: 0,
          EXIT: 0
        }
      })
      this.$router.push({path: '/csLanding'})
    },
    getAllCs(max=100, min=1) {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.Company.getAllCs(max, min)
      .then(res => {
        loadingInstance.close()
        if (res.GetListByChooseResult && res.GetListByChooseResult.length > 0) {
          this.allCS = res.GetListByChooseResult
        }
      })
    },

    getxz(item, idx, type=0) {
      this.itemData = item
      if (type) {
        this.daCsIdx = -1
        this.allCSIdx = idx
      }else {
        this.daCsIdx = idx
        this.allCSIdx = -1
      }
      this.getCSzx()
    },

    getDay() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.Company.getDayCs()
          .then(res => {
            loadingInstance.close()
            if (res.GetListByChooseResult && res.GetListByChooseResult.length > 0) {
              this.dayCs = res.GetListByChooseResult
            }
          })
    },

    getCSzx() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.Company.getCsConsulting(this.itemData.CustomerId)
      .then(res => {
        loadingInstance.close()
        if (res.GetFullListResult && res.GetFullListResult.length > 0) {
          let arr = [],items = res.GetFullListResult[0].ListChooseFull
          if (items &&  items.length > 0) {
            for (let i = 0; i < items.length; i++) {
              items[i].Choose.cjTime = items[i].Choose.ChooseAddTime.split(' ')[0]
              items[i].Choose.testTime = items[i].Choose.ChooseEditTime.split(' ')[0]
              items[i].Choose.ChooseSignPath?items[i].Choose.zt='完成':items[i].Choose.zt='未完成'
              items[i].Choose.bh = items[i].Choose.ChooseId.slice(0, 8).toUpperCase()
              arr.push(items[i].Choose)
            }
          }
          this.consultingArr = arr
        }
      })
    },

    go(chid) {
      this.$router.push({path: `/corneaContact/Iframe?type=6&csid=${this.itemData.CustomerId}&chid=${chid}`})
    },

    searchCs() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      // console.log(this.time)
      this._api.Company.searchCs(this.search, [this.time1, this.time2])
      .then(res => {
        loadingInstance.close()
        if (res.GetListByChooseResult && res.GetListByChooseResult.length) {
          if (this.activeNames == '1') {
            this.dayCs = res.GetListByChooseResult
          }else {
            this.allCS = res.GetListByChooseResult
          }
        }else {
          this.$alert('暂无此顾客就诊信息，请先注册就诊', '提示')
        }
      })
    },

    addshow() {
      if (!this.$store.state.users.employees) {
        return this.$router.push({path: '/employeesLanding'})
      }
      // console.log(this.itemData)
      this.adddata = {
        "CRCompanyId":this.$store.state.users.user.Company.CompanyId,//Insert时必填
        "CRExpertId": this.$store.state.users.employees.ExpertId,//Insert时必填
        "CRChooseId": this.itemData.ChooseId,//可空
        "CRCustomerId": this.itemData.CustomerId,//Insert时必填
        "CRProblem":"",//可空
        //CRAddTime: '',
        "CRContent":"",//Insert时必填
        "CRTime":"",//Insert时必填
        "CRMethod":"",//Insert时必填
        "CRType":"",//Insert时必填
        "CRState":""//Insert时必填
      }
      this.showadd = true
    },

    savecsfh() {
      if (!this.adddata.CRContent || !this.adddata.CRTime || !this.adddata.CRMethod || !this.adddata.CRType || !this.adddata.CRState)
        return this.$alert('请填写必要信息！', '提示')
       let url = 'CustomerReview_Insert'
       if (this.adddata.CRId) {
         url = CustomerReview_Update
       }

      this._api.userCurdApi.CustomerReview_Insert(this.adddata, url)
      .then(res => {
        this.teb(2)
        this.$alert(res.displaymsg, '提示')
      })
    },

    teb(type) {
      this.tebidx = type
      if (type==1) {
        return this.getCSzx()
      }
      if (type == 2) {
        this._api.userCurdApi.CustomerReview_GetList(this.itemData.CustomerId)
        .then(res => {
          if (res.Result && res.Result.length) {
            this.hflist = [...res.Result]
          }else {
            this.hflist = []
          }
        })
      }
    },

    ckhf(idx) {
      this.adddata = this.hflist[idx]
      this.showadd = true
    }
  }
}
</script>

<style scoped lang="scss">
  .myCustomers {
    height: 87vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: start;
  }
  .left {
    width: 20vw;
    height: 100%;
    background: rgba(5, 5, 5, 0.2);
    .searchbox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
    .left-xzbox {
      padding-top: 20px;
      .nr {
        background: rgba(0,0,0,0.3);
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #818181;
        cursor: pointer;
        img {
          display: block;
          width: 30px;
          margin-left: 20px;
        }
        p {
          padding-left: 10px;
          color: #ffffff;
          font-size: 16px;
        }
      }
      .nr:hover {
        background: #929191;
      }
      .xz {background: #929191;}
    }
  }
  .search {
    width: 70%;
    margin-right: 10px;
  }
  ::v-deep .back .el-collapse-item__content {
    max-height: 56vh;
    overflow-y: auto;
  }
  ::v-deep .back .el-collapse-item__header {
    background: #2424d6;
    color: #ffffff;
  }
  ::v-deep .back .el-collapse-item__content {padding: 0;}
  .el-collapse {border: none;}
  .right {
    height: 100%;
    overflow: hidden;
    width: 78vw;
    padding-left: 1vw;
    .titles {
      text-align: left;
      padding-bottom: .2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {cursor: pointer;}

      .tebxz {color: #1053ff;}
    }
    .padding-30 {padding-top: .3rem}
    .padding-50 {padding-top: .5rem}
    ul {
      //width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        p {
          padding: 5px .25rem;
          border-radius: 5px;
          border: 1px solid #000000;
          cursor: pointer;
        }
      }
    }
    .title-ul {
      background: #000000;
      color: #ffffff;
      padding: .1rem;
    }
    .content-ul {
      padding: .15rem;
      border-bottom: 1px solid #000000;
    }
    .content-ul:hover{background: #efecec;}
  }
  .right-box {
    height: 47vh;
    overflow-y: auto;
  }
  .timesp {
    width: 100%;
    margin-top: 2vh;
  }

  .hfwt {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .times {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    .time1{width: 90%}
  }
</style>
